import React from 'react'
import { Layout, Posts, SEO } from '../components'
import { graphql } from 'gatsby'

const CategoryTemplate = props => {
  const {
    data: {
      postsCategories: { nodes: posts },
    },
  } = props
  const {
    data: {
      projectsCategories: { nodes: projects },
    },
  } = props
  const {
    pageContext: { category, categoryWithHyphen },
  } = props

  return (
    <Layout>
      <SEO title={category} />
      {posts.length > 0 ? (
        <Posts
          posts={posts}
          division={posts[0].frontmatter.division}
          category={category}
          categoryWithHyphen={categoryWithHyphen}
        />
      ) : null}

      {projects.length > 0 ? (
        <Posts
          posts={projects}
          division={projects[0].frontmatter.division}
          category={category}
          categoryWithHyphen={categoryWithHyphen}
        />
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query GetCategories($category: String) {
    postsCategories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          category: { eq: $category }
          division: { eq: "post" }
          published: { eq: true }
        }
      }
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
    projectsCategories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          category: { eq: $category }
          division: { eq: "project" }
          published: { eq: true }
        }
      }
    ) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
  }
`

export default CategoryTemplate
